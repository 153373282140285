import styles from "./intro.module.css";
import profile from "../../../asset/profile2.jpg";

function Introduction() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.profile}>
          <img src={profile} className={styles.image} alt="loading"></img>
        </div>
        <div >
          <span className={styles.caption}>Zac Wang</span>
          <div className={styles.description}>
            GIS developer passionate about software engineering
          </div>
        </div>
      </div>
    </>
  );
}

export default Introduction;
