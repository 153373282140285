import Title, { Header } from "../contents/blog-template/header/header";
import Paragraph from "../contents/blog-template/paragraph/paragraph";
import Code from "../contents/blog-template/code/code";
import Ul from "../contents/blog-template/ul/ui";
import Li from "../contents/blog-template/li/li";
import Image from "../contents/blog-template/image/image";
import demoGif from "../../../asset/qgis_demo.gif";
import Time from "../contents/blog-template/time/time";

function Qgis1() {
  return (
    <>
      <Title>How to share data between QGIS3 plugins</Title>

      <Time>2022-07-09</Time>

      {/* <Header>Why we need to share data between two or more plugins</Header> */}
      <Paragraph>
        Once maintaining a dozen of plugins that work closely with each other,
        you may want them to share states and transfer data so that independent
        tools can work as a whole system. For example, one plugin may need to
        get the state of another one. Then it can decide whether some features
        should be activated or not.
      </Paragraph>

      <Header>Approach</Header>
      <Paragraph>
        Users can define custom variables at
        <Code>
          Project-{">"}Properties-{">"}Variables
        </Code>{" "}
        in each QGIS project. Project variables are helpful as you can use them
        in many ways, like defining a custom layer symbology.
      </Paragraph>
      <Paragraph>
        In our case, it works as a global broadcast centre where every plugin
        can read/write variables. Variable values can be any Python data type,
        such as Numbers, List, Dictionary. Let's see how to set and read a
        project variable in a snippet code:
      </Paragraph>
      <Code multiLines={true}>
        # get project object /br project = QgsProject.instance() /br # set a
        project variable /br
        QgsExpressionContextUtils.setProjectVariable(project,'variable','Hello
        World’) /br # read a project variable /br
        QgsExpressionContextUtils.projectScope(project).variable('variable')/br
        &ensp;
      </Code>
      <Paragraph>
        If a plugin wants to keep an eye on the changes of a variable, it can
        connect to the <Code>customVariablesChange</Code> signal. In this way,
        the plugin can get informed whenever a variable has been changed and be
        able to trace the state it cares about:
      </Paragraph>
      <Code multiLines={true}>
        QgsProject.instance().customVariablesChanged.connect(slot) /br def
        slot(self): /br &ensp; &ensp; # check the variable if it’s changed /br
        &ensp; &ensp; current_value =
        QgsExpressionContextUtils.projectScope(project).variable('variable') /br
        &ensp; &ensp; if current_value != original_value: /br &ensp; &ensp; # do
        something here /br &ensp;
      </Code>
      <Paragraph>
        The above method is pretty straightforward to develop and maintain
        compared with customising some signals in a plugin and listen to the
        signals in another plugin.
      </Paragraph>
      <Header>Example</Header>
      <Paragraph>
        Let's have an example to see how it works. I prepared two custom plugins
        named demo1 and demo2. They are always the same number no matter I click
        the add or minus button. By implementing the above logic, we can make
        different plugins get the same number all the time.
      </Paragraph>
      <Image source={demoGif} />
      <Header>Conclusion</Header>
      <Paragraph>
        The above solution is not the only way to make plugins communicate, but
        it's quite easy to implement. The good thing about open-source software
        is that it gives us many ways to attain our goals.
      </Paragraph>
      <Paragraph>
        If you have any questions, please contact me for more details. Also,
        it's worth reading{" "}
        <a href="https://docs.qgis.org/3.22/en/docs/pyqgis_developer_cookbook/index.html">
          PyQGIS Developer Cookbook
        </a>{" "}
        and <a href="https://qgis.org/pyqgis/master/">PyQGIS API</a> if you are
        interested in QGIS plugins.
      </Paragraph>
    </>
  );
}

export default Qgis1;
