import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./leaflet-layout.css";
import L from 'leaflet';
import LeafletMap from './map-component/leaflet-map.js';
import Description from './map-component/description';
import React, { useState, useEffect } from 'react';
import { GestureHandling } from "@zacwang/leaflet-gesture-handling"
import "leaflet/dist/leaflet.css";
import "@zacwang/leaflet-gesture-handling/dist/leaflet-gesture-handling.css";


import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

// https://github.com/PaulLeCam/react-leaflet/issues/453

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

// https://stackoverflow.com/questions/50502664/how-to-update-the-context-value-in-a-provider-from-the-consumer/50502829#50502829
// https://stackoverflow.com/questions/41030361/how-to-update-react-context-from-inside-a-child-component
const ThemeContext = React.createContext(
    {
        theme: 'light',
        setTheme: () => { }
    }
);

function LeafletLayout() {
    const centerAlignment = "justify-content-md-center"

    const [latlng, setLatlng] = useState(null);

    const [theme, setTheme] = useState("light");


    useEffect(() => {
        // console.log(latlng);
    }
    )

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {/* id='leaflet-container' */}
            <Container fluid >
                <Row className={centerAlignment}>
                    <Col lg="2" className="leaflet-desc">
                        <Description latlng={latlng} onLatlngChange={setLatlng} />
                    </Col>
                    <Col lg="7" className="px-0">
                        <LeafletMap latlng={latlng} onLatlngChange={setLatlng} />
                    </Col>
                </Row>
            </Container>
        </ThemeContext.Provider>
    )
}

export default LeafletLayout;
export { ThemeContext };