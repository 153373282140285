let contents = {
  qgis: {
    "How to share data between QGIS3 plugins": { id: 1, title: "How to share data between QGIS3 plugins" },
  },
  // leaflet: {
  //   "item1": { id: 1, title: "item1" },
  //   "item2": { id: 2, title: "item2" },
  // },
};

export function getContents() {
  return contents;
}
