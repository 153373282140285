import './App.css';
import Navigate from './components/navigate/Navigate.js'
import Home from './components/home/Home.js';
import LeafletLayout from './components/maps/leaflet-map/leaflet-layout.js';
import Blog from './components/blogs/blogs.js';
import ErrorPage from './components/error/error-page/error-page.js'
import { Route, Switch } from 'react-router-dom';

// read this tutorial to learn React-Router: https://www.freecodecamp.org/news/react-router-in-5-minutes/

function App() {
  return (
    <div className="App">
      <Navigate />
      <Switch>
        <Route path="/" component={Home} exact/>
        <Route path="/maps/reverse-geocoding" component={LeafletLayout} />
        <Route path="/blogs" component={Blog} />
        {/* <Route path="/blogs/qgis/" component={Contents} exact /> */}
        <Route component={ErrorPage} />
      </Switch>
    </div>
  );
}

export default App;
