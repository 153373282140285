import {
  useParams,
  Redirect,
} from "react-router-dom";
import { getContents } from "../../../data/blogContents";
import BlogTemplate from "./blog-template/blog-template";

function Contents() {
  const contents = getContents();

  let { topic, title } = useParams();

  // get all the titles of a topic, like qgis
  let titles = Object.keys(contents[topic])
  
  return titles.includes(title) ? (
    <BlogTemplate></BlogTemplate>
  ) : (
    <Redirect to="/error-page"></Redirect>
  );
}

export default Contents;
