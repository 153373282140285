import "./leaflet-map.css";
import { MapContainer, TileLayer, Marker, Popup, useMapEvent, useMapEvents } from 'react-leaflet';
import { useState } from "react";


// locate user 
function AddCurrentLocation() {
    const [position, setPosition] = useState(null)

    const map = useMapEvents({
        click: () => {
            map.locate()
        },
        locationfound: (location) => {
            console.log('location found:', location)
            setPosition(location.latlng)
            map.flyTo(location.latlng, map.getZoom())
        },
    })
    return position === null ? null : (
        <Marker position={position}>
            <Popup>You are here</Popup>
        </Marker>
    )
}

function GetLocation(props) {
    const { latlng, onLatlngChange } = props;

    const [position, setPosition] = useState(null)

    const map = useMapEvents({
        click: (event) => {
            // console.log(event.latlng)

            setPosition(event.latlng)
            onLatlngChange(event.latlng)
        },

    })

    return position === null ? null : (
        <Marker position={position}>
            {/* <Popup>Location</Popup> */}
        </Marker>
    )

}

function LeafletMap(props) {
    const location = [-37.8136, 144.9631];

    // get the height of the navbar
    // let height = document.getElementsByClassName("navbar")[0].clientHeight;

    // let mapStyle = {height: "calc(100vh - (56px))"};

    const { latlng, onLatlngChange } = props;

    return (
        <MapContainer
            id="leaflet-map"
            center={location} zoom={13} scrollWheelZoom={true}
            gestureHandling={true}
            // dragging = {!L.Browser.mobile}
            // tap = {!L.Browser.mobile}
            // touchZoom={false}
        >   
            {/* find more base maps from this link: https://leaflet-extras.github.io/leaflet-providers/preview/ */}
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; 
                            <a href="https://carto.com/attributions">CARTO</a>'
                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
            />

            {/* click the map to the geolocation */}
            {/* <AddCurrentLocation /> */}

            {/* click the map to get the location */}
            <GetLocation latlng={latlng} onLatlngChange={onLatlngChange} />
        </MapContainer >
    )
};


export default LeafletMap;