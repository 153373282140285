import styles from './header.module.css'

function Title({children}){
    return <h2 className={styles.title}>{children}</h2>
}

function Header({children}){
    return <h4 className={styles["header"]}>{children}</h4>
}

export {Header};
export default Title;