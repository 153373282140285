import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './Home.css'

function Home() {
    const centerAlignment = "justify-content-md-center"

    return (
        // use this way to override the css: style={{ height: '1000px', border: '1px red solid' }}
        <Container fluid >
            <Row className={centerAlignment}>
                <Col md="8" lg="7">
                    {/* https://www.computerhope.com/issues/ch001662.htm */}
                    <h3 className='title'>About Me</h3>
                    <img src="profile.jpg" className='profile' ></img>
                    <p>
                        My name is Zac. I've got 10 years’ experience in the geospatial
                        and IT industry. I enjoy my role as a Developer to offer enterprise solutions and driving better decisions.
                    </p>
                    <p>
                        I use a range of programming languages as my tools such as Python, JavaScript, SQL with great abilities in map visualisation, data analysis and database maintenance.
                    </p>
                    <p>
                        As a programmer with GIS educational background, I've got a perfect understanding of GIS principles and practices
                        with demonstrated experience in a range of fields.
                        In addtion, I'm enthusiastic about coding and keen on learning cutting-edge technology.
                        I believe being interested in things is the best teacher.
                    </p>

                    <h4>Tools</h4>
                    <ul >
                        <li className='tool-set'>Data: Databricks, Python</li>
                        <li className='tool-set'>Web: React, JavaScript (Leaflet, OpenLayers, Mapbox)</li>
                        <li className='tool-set'>Cloud: Certified AWS Solution Architect - Associate</li>
                        <li className='tool-set'>DevOps: Git, Github, Jenkins</li>
                        <li className='tool-set'>Database: PostgreSQL(PL/pgSQL), SQL Server, etc.</li>
                        <li className='tool-set'>ESRI(ArcGIS) Series and Geocortex</li>
                    </ul>
                    <h4 className='heading3-main-page'>Contact</h4>
                    <p>
                        Feel free to contact me via <a href="mailto:zacwang15@gmail.com">Email</a> or
                        <a href="https://www.linkedin.com/in/zac-wang"> LinkedIn</a>.
                    </p>
                </Col>
            </Row>
        </Container >
    )
}

export default Home;