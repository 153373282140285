import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import { getContents } from "../../../../data/blogContents";
import Qgis1 from "../../articles/qgis1";
import Introduction from "../../intro/intro";

function Article(props) {
  const { title } = props;

  return title === "How to share data between QGIS3 plugins" ? (
    <Qgis1 />
  ) : null;
}

function BlogTemplate() {
  const centerAlignment = "justify-content-md-center";

  let { title } = useParams();
  const contents = getContents();
  return (
    <Container>
      <Row className={centerAlignment}>
        <Col md="1"></Col>
        <Col md={{ span: 3, order: 2 }}>
          <Introduction />
        </Col>
        <Col md={{ span: 8, order: 1 }}>
          <Article title={title} />
        </Col>
      </Row>
    </Container>
  );
}

export default BlogTemplate;
