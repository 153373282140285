import styles from "./code.module.css";

function Code({ children, multiLines }) {
  if (multiLines) {
    const lines = children.split('/br')

    const elements = lines.map((line, index) => (
      <div key={index}>
        <code className={styles.code}>{line}</code> <br />
      </div>
    ));
    return <div className={styles["multi-line"]}>{elements}</div>;
  }

  return <code className={`${styles.code} ${styles["single-line"]}`}>{children}</code>;
}

export default Code;
