import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useState } from 'react';
// import Container from 'react-bootstrap/Container'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
import './Navigate.css';
// import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

function Navigate() {
    const [expanded, setExpanded] = useState(false);

    return (
        // onToggle={(e)=>{console.log(e)}}
        // <Container fluid className="p-0">
        //     <Row noGutters>
        //     <Col>
        // style={{ 'padding-left': '10rem' }} react style should use paddingLeft

        // className='px-lg-5': set padding dynamically(boostrap spacing https://mdbootstrap.com/docs/react/utilities/spacing/)

        // react doesn't support inline !important, use the method below to achieve it  https://stackoverflow.com/questions/23074748/important-inline-styles-in-react
        // ref={(node) => {
        //     if (node) {
        //       node.style.setProperty("padding-right", "15rem", "important");
        //     }
        // }}

        // px-lg-5 mx-lg-5 
        // custom px-md-6 px-lg-10:  https://stackoverflow.com/questions/46119384/bootstrap-4-add-more-sizes-spacing
        // navbar collapse on mobile: https://stackoverflow.com/questions/32452695/react-bootstrap-how-to-collapse-menu-when-item-is-selected
        <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect className='px-md-6 px-lg-10' expanded={expanded}>
            <Navbar.Brand as={Link} to="/" >Zac's Page</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}>About</Nav.Link>
                    <Nav.Link as={Link} to="/blogs" onClick={() => setExpanded(false)}>Blogs</Nav.Link>
                    <NavDropdown title="Maps" id="basic-nav-dropdown">
                        {/* make React-Router works with Bootstrap: https://stackoverflow.com/questions/54843302/reactjs-bootstrap-navbar-and-routing-not-working-together */}
                        <NavDropdown.Item as={Link} to="/maps/reverse-geocoding" onClick={() => setExpanded(false)}>
                            Reverse Geocoding
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                <Nav>
                    <Nav.Link href="https://www.linkedin.com/in/zac-wang">Linkedin</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        //         </Col>
        //     </Row> 
        // </Container>
    )
}


export default Navigate;