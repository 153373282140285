import { useEffect, useState, useContext } from "react";
import { ThemeContext } from "../leaflet-layout";
import { Loader } from "@googlemaps/js-api-loader";
import styles from "./description.module.css"

const loader = new Loader({
  apiKey: "AIzaSyBeMqo4Sz0wHqJgYQCene7RVj-m9zpVvac",
  version: "weekly",
});

function Description(props) {
  const { latlng } = props;

  // create a new avariable to track if the props.latlng updated
  const [newLatlng, setNewLatlng] = useState(latlng);

  const [address, setAddress] = useState(null);

  useEffect(() => {
    if (latlng !== newLatlng) {
      setNewLatlng(latlng);
      reverseGeocode(latlng.lat, latlng.lng, setAddress);
    }
  }, [latlng]);

  // consume theme from ThemeContext defined in leaflet-layout.js
  const { theme, setTheme } = useContext(ThemeContext);

  return (
    <>
      <p className={styles.description}>
        <strong>Latitude: &nbsp;</strong>
        <em>{latlng === null ? "" : latlng.lat.toFixed(6)}</em> <br/>
    
        <strong>Longitude: &nbsp;</strong>
        <em>{latlng === null ? "" : latlng.lng.toFixed(6)}</em> <br/>
      
        <strong>The address:</strong> <br />
        <em>{address ? address : "You have exceeded reuqest limit.You have exceeded reuqest limit.You have exceeded reuqest limit."}</em>
      </p>

      {/* <p>{theme}</p>
            <button onClick={()=>theme==='light'? setTheme('dark'): setTheme('light')}>{theme}</button> */}
    </>
  );
}

function reverseGeocode(latitude, longitude, setAddress) {
  loader
    .load()
    .then((google) => {
      const geocoder = new google.maps.Geocoder();

      return geocoder;
    })
    .then((geocoder) => {
      const latlng = {
        lat: latitude,
        lng: longitude,
      };
      geocoder.geocode({ location: latlng }, function (results, status) {
        console.log(results);
        if (status === "OK") {
          let location = results[0].formatted_address;
          setAddress(location);
        } else {
          setAddress("Couldnt't find the location");
        }
      });
    });
}

export default Description;
