import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams, useRouteMatch, Link, Redirect } from "react-router-dom";
import style from "./category.module.css";
import { getContents } from "../../../data/blogContents";

function Catalogue() {
  let { topic } = useParams();

  let { url } = useRouteMatch();
  // notice path and url are different in this case
  // path: /blogs/:topic
  // url: /blogs/qgis
  // console.log(path, url);

  const centerAlignment = "justify-content-center";

  const contents = getContents();

  const listItems = Object.keys(contents).includes(topic) ? (
    <ul className={style["list-items"]}>
      {Object.keys(contents[topic]).map((title, index) => (
        <li key={index} className={style.item}>
          <Link to={`${url}/${title}`} style={{ color: "black" }}>
            {title}
          </Link>
        </li>
      ))}
    </ul>
  ) : null;

  return contents[topic] ? (
    <Container>
      <Row className={centerAlignment}>
        <Col md="10" lg="8">
          <h3 style={{ textAlign: "center", paddingTop: "1rem" }}>Contents</h3>
          {listItems}
        </Col>
      </Row>
    </Container>
  ) : (
    <Redirect to="/error-page"></Redirect>
  );
}

export default Catalogue;
